
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { fixPrice } from '@/utils/general'

@Component({
  components: {
    Row: () => import('../simple.vue'),
  },
  methods: { fixPrice },
})
  export default class LastAppraisalResponse extends Vue {
  @Prop({ type: [String, Number, Object, Array] }) value!: any
  @Prop({ type: String }) label!: string
  @Prop({ type: Boolean, default: false }) opened: boolean

  panel? = -1

  get responses () {
    const { value } = this
    if (!value?.length) return []

    return value.map(response => {
      return {
        name: response?.type?.description,
        value: response?.amount,
        comment: response?.comment,
        date: response?.created,
      }
    }).filter(_ => _.value)
  }

  get title () {
    const { lastPrice } = this

    return !lastPrice ? 'Precio esperado' : 'Último precio tasado'
  }

  get lastPrice () {
    const { value } = this

    const lastAppraised = value.filter(appraisal => appraisal.isAppraised)
    if (!lastAppraised.length) return null

    return lastAppraised[0].amount
  }

  get expectedOffer () {
    const { value } = this

    const expectedOffer = value.filter(appraisal => appraisal.isExpectedOffer)
    if (!expectedOffer.length) return null

    return expectedOffer[0].amount
  }

  get displayPrice () {
    const { responses, lastPrice } = this

    if (!responses?.length) return false

    return responses.length > 1 || (responses.length === 1 && Boolean(lastPrice))
  }

  @Watch('opened', { immediate: true }) onChange (val) {
    this.panel = val ? 0 : -1
  }
  }
